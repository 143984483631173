import RoleEnum from '@/common/role.enum'

export default [
  // {
  //   title: 'Announcement',
  //   route: 'announcement',
  //   icon: 'HomeIcon'
  // },
  {
    header: 'User Management'
  },
  {
    title: 'Group',
    route: 'user-group',
    icon: 'UsersIcon'
  },
  {
    title: 'Team',
    route: 'user-team',
    icon: 'UsersIcon'
  },
  {
    title: 'User',
    route: 'user-user',
    icon: 'UserIcon'
  },
  {
    header: 'Categories Management'
  },
  {
    title: 'Categories',
    route: 'categories-management',
    sub: ['categories-add'],
    icon: 'ListIcon'
  },
  {
    header: 'Customer Configuration'
  },
  {
    title: 'Customer Group',
    route: 'customer-group',
    icon: 'UsersIcon'
  },
  {
    title: 'Customer Type',
    route: 'customer-type',
    icon: 'UsersIcon'
  },
  {
    title: 'Company Center',
    route: 'company-center',
    icon: 'UsersIcon'
  },
  {
    title: 'Company Group',
    route: 'company-group',
    icon: 'UsersIcon'
  },
  {
    title: 'Company Department',
    route: 'company-department',
    icon: 'UsersIcon'
  },
  {
    header: 'Ticket Configuration'
  },
  // {
  //   title: 'Call Category',
  //   route: 'categories-call',
  //   icon: 'ListIcon'
  // },
  {
    title: 'Contact Channels',
    route: 'ticket-contact',
    icon: 'BookIcon'
  },
  {
    title: 'Ticket Type',
    route: 'ticket-type',
    icon: 'TagIcon'
  },
  {
    title: 'Plant',
    route: 'plant',
    icon: 'FileTextIcon'
  },
  {
    title: 'Skill Name',
    route: 'skill-name',
    icon: 'FileTextIcon'
  },
  {
    header: 'Knowledge',
    role: [RoleEnum.SUPER_ADMIN, RoleEnum.SUPERVISOR, RoleEnum.MANAGEMENT, RoleEnum.MANAGER]
  },
  {
    title: 'Knowledge Base',
    route: 'other-knowledge',
    sub: ['other-knowledge-add'],
    icon: 'SunIcon',
    role: [RoleEnum.SUPER_ADMIN, RoleEnum.SUPERVISOR, RoleEnum.MANAGEMENT, RoleEnum.MANAGER]
  },
  {
    title: 'Knowledge Type',
    route: 'knowledge-type',
    icon: 'SunIcon',
    role: [RoleEnum.SUPER_ADMIN]
  },
  {
    title: 'Knowledge Categories Mapping',
    route: 'knowledge-categories-mapping',
    icon: 'SunIcon',
    role: [RoleEnum.SUPER_ADMIN]
  },
  {
    header: 'Others'
  },
  {
    title: 'Holidays',
    route: 'other-working',
    icon: 'CalendarIcon'
  },
  {
    header: 'New'
  },
  {
    title: 'Contact Type',
    route: 'contact-type',
    icon: 'FileTextIcon'
  },
  {
    title: 'Contact Relation',
    route: 'contact-relation',
    icon: 'FileTextIcon'
  },
  {
    title: 'Solution Type',
    route: 'solution-type',
    icon: 'FileTextIcon'
  },
  {
    title: 'Special Case',
    route: 'special-case',
    icon: 'FileTextIcon'
  },
  {
    title: 'Email Group',
    route: 'email-group',
    icon: 'FileTextIcon'
  },
  {
    title: 'Email Template',
    route: 'email-template',
    icon: 'FileTextIcon'
  },
  {
    title: 'SMS Template',
    route: 'sms-template',
    icon: 'FileTextIcon'
  },
  {
    title: 'Form Template',
    route: 'form-template',
    icon: 'FileTextIcon'
  },
  {
    title: 'OIC List',
    route: 'oic-list',
    icon: 'FileTextIcon'
  },
  {
    title: 'Hospital Contact',
    route: 'hospital-contact',
    icon: 'FileTextIcon'
  },
  {
    title: 'Court Arbitration',
    route: 'court-arbitration',
    icon: 'FileTextIcon'
  }
]
